export const setZipCode = (zipCode) => {
    return {
        type: "SET_ZIPCODE",
        zipCode: zipCode
    }
}
export const setSelectedDate = (selectedDate) => {
    return {
        type: "SET_SELECTED_DATE",
        selectedDate: selectedDate
    }
}
export const setSelectedMovies = (selectedMovies) => {
    return {
        type: "SET_SELECTED_MOVIES",
        selectedMovies: selectedMovies
    }
}