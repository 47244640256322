import React, {Component, Fragment} from 'react';
import { connect } from 'react-redux';
import {Map, GoogleApiWrapper} from 'google-maps-react';
import styles from './Results.module.css';
import clockRed from '../../images/clockRed.png';
import clockYellow from '../../images/clockYellow.png';
import clockGreen from '../../images/clockGreen.png';
import { API } from 'aws-amplify';

const style = {
    margin: '0 10px',
    
}

const containerStyle = {
    position: 'relative',
    height: '90vh',
    width: '50%'
}

class Results extends Component {
    state = {
        theaterShowtimes: [],
        theaterCoordinates: {lat: 39.82, lng: -98.57},
        zoomLevel: 4,
        places: [],
        loading: true
    }


    componentDidMount = async () => {

        let response = await API.post('myEphemerisAPI', '/results',{
            headers: {
              'Content-Type': 'application/json'
            },
            body: {
                zipCode: this.props.zipCode,
                selectedDate: this.props.selectedDate,
                selectedMovies: this.props.selectedMovies
            }
         })
        this.setState({theaterShowtimes: response.theaterShowtimes, loading: false})
        
        window.addEventListener('resize', this.updateDimensions);

    } 
    onResultClick = (lat, lng) => {
        this.setState({zoomLevel: 17})
        this.setState({theaterCoordinates: {lat: lat, lng: lng }})
    }
    render() {
        if (window.innerWidth <= 700){
            containerStyle.width = '100%'
        }
        else{
            containerStyle.width = '50%'
        }
        return (
            <Fragment>
                <div className={styles["result-page"]}>
                    <Map google={this.props.google}  zoom={this.state.zoomLevel} center={this.state.theaterCoordinates} style={style} containerStyle={containerStyle}/>    
                    {this.state.loading ?<div className={styles["loading-indicator"]}> <div className={styles["lds-ellipsis"]}><div></div><div></div><div></div><div></div></div> </div>:   
                    <div className={styles["result-list"]}>
                        {this.state.theaterShowtimes.map( (optimalShowtimes) => (
                                <div className={styles["result"]} onClick={() => this.onResultClick(optimalShowtimes.theaterCoordinates.lat, optimalShowtimes.theaterCoordinates.lng)}>
                                    <h1 className={styles["cinema-name"]}>{optimalShowtimes.theaterName}</h1>
                                    <div className={styles["result-items"]}>
                                        <div className={styles["result-item"]}>
                                            {(() =>  {
                                                if((optimalShowtimes.totalDowntime/optimalShowtimes.showtimeOrderings.length) > 60){
                                                    return (<Fragment>
                                                            <img src={clockRed} className={styles["clock"]} />
                                                            <p>{optimalShowtimes.totalDowntime + " minutes"}</p>
                                                            </Fragment>)
                                                }
                                                else if((optimalShowtimes.totalDowntime/optimalShowtimes.showtimeOrderings.length) > 30){
                                                    return (<Fragment>
                                                        <img src={clockYellow} className={styles["clock"]} />
                                                        <p>{optimalShowtimes.totalDowntime + " minutes"}</p>
                                                        </Fragment>)
                                                }
                                                else 
                                                return (<Fragment>
                                                    <img src={clockGreen} className={styles["clock"]} />
                                                    <p>{optimalShowtimes.totalDowntime + " minutes"}</p>
                                                    </Fragment>)
                                            

                                            })()}
                                        </div>
                                        {optimalShowtimes.showtimeOrderings.map(optimalShowing => {
                                            return(
                                                <div className={styles["result-item"]}>
                                                    <p className={styles["film-name"]}>{optimalShowing.movieName } </p>
                                                    <p className={styles["start-end-time"]}>{optimalShowing.startTime + "-" + optimalShowing.endTime  } </p>
                                                </div>
                                                
                                            )
                                        })}
                                    </div>
                                    
                                </div>)
                            )
                        }
                    </div>
                    }
                </div>
            </Fragment>     
            
        )
    }
}

const mapStateToProps = state => {
    return {
        zipCode: state.zipCode,
        selectedDate: state.selectedDate,
        selectedMovies: state.selectedMovies
    }
}


export default GoogleApiWrapper({apiKey: 'AIzaSyBQjuXh4AlTmeMRp8Q0cxnzn8CwO1sMXdI'})(connect(mapStateToProps)(Results));