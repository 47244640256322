import React, {Component} from 'react';
import styles from "./Walkthrough.module.css";
import homepage from '../../../images/homepage.png'
import resultspage from '../../../images/resultspage.png';
import theater from '../../../images/theater.png';
import rocket from '../../../images/rocket.png';
// window.addEventListener('scroll', () => {
//     console.log((window.pageYOffset + window.innerHeight)/ (document.body.offsetHeight));
//     document.body.style.setProperty('--scroll',window.pageYOffset / (document.body.offsetHeight - window.innerHeight));
//   }, false);
document.body.style.setProperty('--scroll',0);


class Walkthrough extends Component {
    state = {
        highlightedStep: 1
    };
    componentDidMount = () =>  {
        window.addEventListener('wheel', this.handleWheel);
    }
    handleWheel = (event) => {
        let currScroll =  parseFloat(document.body.style.getPropertyValue('--scroll'));
        if ((window.innerHeight + window.scrollY >= document.documentElement.scrollHeight) && (currScroll < 3.9)   ) {
            document.body.style.setProperty('--scroll', currScroll + 0.05);
            if(currScroll/3.9 > 2/3){
                this.setState({highlightedStep: 3});
            }
            else if(currScroll/3.9 > 1/3){
                this.setState({highlightedStep: 2});
            }
            else{
                this.setState({highlightedStep: 1});
            }
        }
        else if ((window.innerHeight + window.scrollY < document.documentElement.scrollHeight-50) && (currScroll > 0)  ) {
            document.body.style.setProperty('--scroll', 0);
            this.setState({highlightedStep: 1});
        }
    }
    render(){
        return(
            <div className={styles["walkthrough"]}>
                <h1 className={styles["title"]}>Save time at the movies</h1>
                <div className={styles["steps"]}>
                    <div className={this.state.highlightedStep === 1 ? `${styles["step-one"]} ${styles["highlighted"]}`: styles["step-one"]}>
                        <img className={styles["step-one-image"]} src={homepage}/>
                        <p>Select your movies</p>
                    </div>
                    <div className={this.state.highlightedStep === 2 ? `${styles["step-two"]} ${styles["highlighted"]}`: styles["step-two"]}>
                        <img className={styles["step-two-image"]} src={resultspage}/>
                        <p>Select optimal show times</p>
                    </div>
                    <div className={this.state.highlightedStep === 3 ? `${styles["step-three"]} ${styles["highlighted"]}`: styles["step-three"]}>
                        <img className={styles["step-three-image"]} src={theater}/>
                        <p>Enjoy the saved time!</p>
                    </div>
                </div>
                <img className={styles["rocket-image"]} src={rocket}/>
            </div>
        )
    }
}



export default Walkthrough;