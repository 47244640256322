import React, {Component, Fragment} from 'react';
import MovieSelection from './MovieSelection/MovieSelector';
import Walkthrough from './Walkthrough/Walkthrough';

class Home extends Component {
    render() {
        return (
            <Fragment>
                <MovieSelection />
                <Walkthrough />
            </Fragment>
        )
    }
}

export default Home;