import React, {Component, Fragment} from 'react';
import {Switch, Route} from "react-router-dom";
import Navigation from './components/Navigation/Navigation';
import Home from './components/Home/Home';
import Results from './components/Results/Results';
import About from './components/About/About';


class App extends Component {
    state = {
        
    };
    render() {
        return (
            <Fragment>
                <Navigation/>
                <Switch>
                    <Route path="/results">
                        <Results />
                    </Route>
                    <Route path="/">
                        <Home />
                    </Route>
                </Switch>
            </Fragment>
        )
    }
}

export default App;
