import React from 'react';
import {Link} from "react-router-dom";

import styles from './Navigation.module.css';
import logo from '../../images/logo.png';
const navigation = (props) => (
    <nav className={styles.nav}>
        <img src={logo} className={styles.logo}/>
        <div className={styles.spacer}/>
        <ul className={styles.navItems}>
            <Link to="/">
                <li className={styles.navItem}>Home</li>
            </Link>
        </ul>
 
    </nav>
);

export default navigation;