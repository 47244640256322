const initialState = {
    zipCode: "00000",
    radius: 0,
    selectedMovies: []
}

const reducer = (state = initialState, action) => {
    switch(action.type){
        case "SET_ZIPCODE":
            return {...state, zipCode: action.zipCode }
        case "SET_SELECTED_DATE":
            return {...state, selectedDate: action.selectedDate }
        case "SET_SELECTED_MOVIES":
            return {...state, selectedMovies: action.selectedMovies }
        default: 
            return state;
    }
}

export default reducer;