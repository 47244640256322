import React, {Component} from 'react';



class About extends Component {
    render() {
        return (
            <div>
                Welcome to the about page
            </div>
        )
    }
}

export default About;