import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import * as actions from '../../../store/actions/movieSelection';
import { withRouter } from "react-router";
import styles from './MovieSelection.module.css';
import { API } from 'aws-amplify';
import DatePicker from "react-datepicker";

import "./MovieSelection.css"
import "react-datepicker/dist/react-datepicker.css";



class MovieSelection extends Component {
    state = {
        showSuggestions: false,
        nowShowing: [],
        suggestedMovies: [],
        selectedMovies: [],
        selectedDate: new Date()
    }
    componentDidMount = async () => {
        let response = await API.get('myEphemerisAPI', '/search/nowShowing')
        this.setState({nowShowing: response.nowShowing })
           
    }
    onSearchChange = event => {
        const userInput = event.currentTarget.value;
        const filteredMovies = this.state.nowShowing.filter(movie => 
            movie.movieName.toLowerCase().indexOf(userInput.toLowerCase()) > -1
        )
        
        this.setState({showSuggestions: true, suggestedMovies: filteredMovies})
    }
    onSuggestionClick = (movie) => {
        if(!this.state.selectedMovies.includes(movie)){
            this.setState({selectedMovies: this.state.selectedMovies.concat(movie)});
        }

    }
    onSelectedClick = (movie) => {
            this.setState({selectedMovies: this.state.selectedMovies.filter((m) => m.movieName != movie.movieName)});
    }
    onDateChange = (newDate) => {
        this.setState({selectedDate: newDate})
    }
    onBackgroundClick  = event => {
        this.setState({showSuggestions: false})
    }
    onSubmit = event => {
        event.preventDefault();
        this.props.setZipCode(event.target["zip-code"].value);
        this.props.setSelectedDate(this.state.selectedDate.toJSON())
        this.props.setSelectedMovies(this.state.selectedMovies);
        this.props.history.replace('/results');
    }
    
    render() {
        const suggestions =  (
            <div className={styles["suggested-movies"]}>
                {this.state.suggestedMovies.map(movie => (
                    <div className={styles["suggested-movie"]}>
                        <img className={styles["poster"]}  src={movie.posterURL} onClick={() => this.onSuggestionClick(movie)} />
                        <div className={styles["add-movie-tip"]}>Add</div>
                    </div>
                 
                ))}
            </div>)
        const selected = (
            <div className={styles["selected-movies"]}>
                {this.state.selectedMovies.map(movie => (
                    <div className={styles["selected-movie"]}>
                        <img className={styles["poster"]} src={movie.posterURL} onClick={() => this.onSelectedClick(movie)} />
                        <div className={styles["remove-movie-tip"]}>Remove</div>
                    </div>
                ))}
            </div>)
        
        return (
            <form onClick={this.onBackgroundClick}  onSubmit={this.onSubmit} className={styles["movie-selector"]}>
                <div className={styles["selector-inputs"]}>
                    <input name="zip-code" className={styles["zip-code"]} placeholder="Zip" required pattern="\d{5}"/>
                    <DatePicker  selected={this.state.selectedDate} onChange={this.onDateChange }/> 
                    <div className={styles["search-suggested-movies"]}>
                        <input name="search" onChange={this.onSearchChange} className={styles["search"]} placeholder="Search for a movie..." autocomplete="off"/>
                        {this.state.showSuggestions? suggestions: null}
                    </div>
                </div>
                {selected}
                <button type="submit" className={styles["submit"]}>Submit</button>
              
            </form>   

        )
    }
}

const mapStateToProps = state =>{
    return {
        zipCode: state.zipCode,
        selectedDate: state.selectedDate,
        selectedMovies: state.selectedMovies
    };
}

const mapDispatchToProps = dispatch => {
    return {
        setZipCode: (zipCode) => dispatch(actions.setZipCode(zipCode)),
        setSelectedDate: (date) => dispatch(actions.setSelectedDate(date)),
        setSelectedMovies: (selectedMovies) => dispatch(actions.setSelectedMovies(selectedMovies))
    }
}

export default withRouter(connect(mapStateToProps,mapDispatchToProps)(MovieSelection));